import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import ServiceBox from "./ServiceBox"
import floorImage from "../images/services/floors.jpg"
import blindImage from "../images/services/blinds.jpg"
import carpetImage from "../images/services/carpets.jpg"
import cleaningImage from "../images/services/cleaning.jpg"
import curtainsImage from "../images/services/curtains.jpg"
import rugImage from "../images/services/rug.jpg"


import Swiper from 'react-id-swiper'
import "./swiper.css"
import "./swiper-services.css"

const Wrapper = styled.div`
  width:100%;
      min-height: 30vh;
      height:auto;
      background-color:rgb(242, 245, 248);
      background-color:white;
      padding: 4em 0em;

      > .swiper-container > .swiper-wrapper > .swiper-slide {
        min-width:300px;
        max-width:500px;
      }
`

const swiperParams = {
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      // spaceBetween: 30,
      loop: true,
      loopedSlides: 2,
      // loopFillGroupWithBlank: true,
      centeredSlides: true,
      initialSlide: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    };

const ServiceBoxes = ({ children }) => (
  <Wrapper>
  <Swiper {...swiperParams}>

    <div>
      <ServiceBox title="Luxury Vinyl & Wood Floors" 
    text="Our extensive library of sheet materials, LVT and safety floors we have many products to show you and meet your requirements. From impervious backed carpet for Nursing Homes to slip resistant products for Schools and Offices to mention but a few of the products we can offer.  We are just a phone call away to have Trevor come around and find you the best products all at competitive prices."
    image={floorImage} />
    </div>

    <div>
      <ServiceBox title="Carpets" 
    text="If you have a nursing home or a luxury hotel, we have products and expertise to come up with the right material and design to suit your new or refurbished project. We can design and install and bespoke Axminster with our latest computer software to finding the right product for your offices, workplace or school classroom just call Trevor and he will be delighted to come and see you and offer advice, we also work very closely with a designer based in York who will also assist to bring your ideas to fruition."
    image={carpetImage} />
    </div>
    <div>
      <ServiceBox title="Cleaning Services" 
    text="We have all the latest equipment to come to your home and give your carpet ,vinyl or 3 piece suite a super deep clean that will bring back your old carpets or suite to life again. We have formulas for most stains just give us a call and we can send out a member of the cleaning staﬀ to access your needs."
    image={cleaningImage} />
    </div>
  </Swiper>
  </Wrapper>
)


export default ServiceBoxes
