import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'


const Wrapper = styled.div`
      min-height: 30vh;
      height:auto;
      // overflow:hidden;
      background-color:white;
    display:flex;
    flex-direction: column;
    align-self:center;
    justify-content:space-evenly;
    transition: 0.2s;
    padding-bottom:50px;
      flex-shrink: 0;

//       & :hover { {
//   z-index: 500;
//     /*margin-left: -10%;*/
//   margin-top:-5%;
//   /*width:120%;*/
//   transition: 0.3s;
//   box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.2), 0 60px 200px 0 rgba(0, 0, 0, 0.19);


// }
}
`
const Title = styled.div`
  padding: 20px 20px;
  font-family: Roboto;
  color: ${props => props.color};
  font-size:1.5em;
  font-family: "Advent Pro", Arial, Helvetica, sans-serif;
  &:hover{
  color: rgb(227, 103, 78); }
  font-size:em;
  font-stretch:100%;
  font-weight:700;
  text-align: center;
    align-self:center;
    transition:0.2s;

  border-bottom: 5px solid grey;
`

const Text = styled.div`
  padding: 20px 20px;
  font-family: Lato;
  color: black;
  font-size:1em;
  font-stretch:100%;
  // font-weight:700;
  text-align: center;
    align-self:center;
`

const ImageWrapper = styled.div`
  border-top: 10px  solid rgb(227, 103, 78);
  height:350px;

  > img { object-fit: cover; }
`

const ServiceBox = ({ title, text, image }) => (
<Wrapper>
  <ImageWrapper><img src={image} height='100%' width='100%' alt='Trevor Wharton Contracts'/></ImageWrapper>
  <Title color="rgb(227, 103, 78)"> {title} </Title>
  <Text> {text} </Text>
</Wrapper>
)


export default ServiceBox
